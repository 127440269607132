import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import Gallery from "../components/Gallery"
import { GatsbyImage } from "gatsby-plugin-image"
import Commission from "../components/Commission"
export default function PortfolioPostTemplate({ data: { markdownRemark } }) {
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <h1 className={'text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold my-24 max-w-screen-lg'}>{frontmatter.title}</h1>
        {frontmatter.coverImage &&
        <div className={'mb-4'}><GatsbyImage image={frontmatter.coverImage.childImageSharp.gatsbyImageData}
          alt={frontmatter.title}
        /></div>
        }
        {html &&
          <div className={'prose prose-lg lg:prose-2xl mb-8'} dangerouslySetInnerHTML={{ __html: html }} />
        }
        {frontmatter.gallery &&
          <Gallery images={frontmatter.gallery} />
        }
      </main>
      <Commission />
    </Layout>
  );
}

export const Head = ({ data: { markdownRemark: post } }) => (
    <>
    <title>{post.frontmatter.title} - Gill Morrow Art</title>
    <meta name="description" content={post.frontmatter.description} />
    </>
)

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        title
        coverImage {
          childImageSharp {
            gatsbyImageData(
              width: 1232,
              height: 720,
              formats:[AUTO, WEBP, AVIF],
              placeholder: DOMINANT_COLOR,
              quality: 75,
              transformOptions:{
                cropFocus: ATTENTION,
                fit: COVER
              }
            )
          }
        }
        gallery {
           childImageSharp {
             gatsbyImageData(
               width: 1000,
               height: 1200,
               formats:[AUTO, WEBP, AVIF],
               placeholder: DOMINANT_COLOR,
               quality: 75,
               transformOptions:{
                 cropFocus: ATTENTION,
                 fit: COVER
               }
             )
           }
         }
      }
    }
  }
`;
